/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://7dqdxwywva.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://24i2vnfehzggboqyjvynam7mmq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:1b71b841-f729-4de9-95b8-40dec008f257",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_a566htPRp",
    "aws_user_pools_web_client_id": "5tns7f32ndrni9ojq1iuh42268",
    "oauth": {
        "domain": 'cevapro-develop.auth.us-east-1.amazoncognito.com',
        "scope": ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        "redirectSignIn": 'https://cevapro.izap.dev/',
        "redirectSignOut": 'https://cevapro.izap.dev/',
        "responseType": 'code' 
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cevaprobucket02242-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
